import { Box, Text, Container, Flex, Heading } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"
import { Mousewheel, Navigation, Pagination, Keyboard } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import ArrowRight from "../../images/arrow-white.inline.svg"
import ArrowLeft from "../../images/arrow-left-white.inline.svg"

// Import Swiper styles
import "swiper/swiper.min.css"
import RichContentStructuredText from "../richContentStructuredText"

const LandingitemCarousel = ({ items, title }) => {
  const [isBegin, setIsBegin] = useState(true)
  const [isEnd, setIsEnd] = useState(false)
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)

  const customContainerRef = useRef()
  const [marginLeft, setMarginLeft] = useState()

  useEffect(() => {
    setMarginLeft(customContainerRef.current.getBoundingClientRect().left)
    function handleResize() {
      setMarginLeft(customContainerRef.current.getBoundingClientRect().left)
      console.log(customContainerRef.current.getBoundingClientRect())
    }
    window.addEventListener("resize", handleResize)
  }, [])

  return (
    <Box
      sx={{
        position: "relative",
        py: [5],
        ".mySwiper .swiper-wrapper .swiper-slide:first-child": {
          ml: marginLeft,
          pl: [3, 4],
        },
        ".mySwiper .swiper-wrapper .swiper-slide:last-child": {
          mr: [marginLeft / 2 - 16, marginLeft / 2 - 32],
        },
        ".mySwiper .swiper-slide": {
          maxWidth: "380px",
          mb: [3, 6],
          height: "auto",
          img: {
            height: "100%",
          },
        },
      }}
    >
      <Container
        className="customcontainer"
        ref={customContainerRef}
        sx={{ py: [0, 0] }}
      ></Container>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          pb: [4, 6],
        }}
      >
        <Heading
          as="div"
          dangerouslySetInnerHTML={{ __html: title }}
          sx={{
            width: ["100%", "1000px"],
            "h1,h2": {
              mt: [0],
              mb: [3],
              fontFamily: "heading",
              lineHeight: "heading",
              fontWeight: "heading",
              fontSize: [5, 7],
              color: "dark",
              em: {
                color: "light",
                fontStyle: "normal",
              },
            },
          }}
        />
        <Flex sx={{ display: ["flex"], justifyContent: "flex-end" }}>
          <Box as="div" ref={node => setPrevEl(node)} sx={{ mr: ["-2px"] }}>
            <Flex
              sx={{
                p: [2],
                cursor: "pointer",
                alignItems: "center",
                alignContent: "center",
                svg: {
                  opacity: isBegin ? 0.5 : 1,
                  display: ["none", "none", "block", "block"],
                  height: ["80px"],
                },
              }}
            >
              <ArrowLeft />
            </Flex>
          </Box>
          <Box as="div" className="nextEl" ref={node => setNextEl(node)}>
            <Flex
              sx={{
                p: [2],
                cursor: "pointer",
                alignItems: "center",
                alignContent: "center",
                svg: {
                  opacity: isEnd ? 0.5 : 1,
                  display: ["none", "none", "block", "block"],
                  height: ["80px"],
                },
              }}
            >
              <ArrowRight />
            </Flex>
          </Box>
        </Flex>
      </Container>

      <Swiper
        modules={[Navigation, Mousewheel, Pagination, Keyboard]}
        mousewheel={{
          forceToAxis: true,
          sensitivity: 1,
          releaseOnEdges: true,
        }}
        autoHeight={false}
        className="mySwiper"
        spaceBetween={30}
        slidesPerView="auto"
        draggable={true}
        onSlideChange={swiperCore => {
          setIsBegin(swiperCore.isBeginning)
          setIsEnd(false)
        }}
        onReachEnd={(swiperCore) => {
          setIsEnd(swiperCore.isEnd)
        }}
        onSwiper={swiper => console.log(swiper)}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        navigation={{ prevEl, nextEl }}
      >
        {items.map((item, index) => (
          <SwiperSlide>
            <Box
              key={item.id}
              sx={{
                px: [3, 0],
                maxWidth: "380px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyItems: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  minWidth: ["150px", "250px"],
                  minHeight: ["150px", "250px"],
                  maxWidth: ["150px", "250px"],
                  maxHeight: ["150px", "250px"],
                  backgroundColor: "light",
                  borderRadius: "100%",
                  ".gatsby-image-wrapper": {
                    height: "auto",
                    maxWidth: ["150px", "250px"],
                    maxHeight: ["150px", "250px"],
                  },
                  img: {
                    height: "100%",
                  },
                }}
              >
                {item.image && item.image.gatsbyImageData && (
                  <GatsbyImage image={item.image.gatsbyImageData} alt="" />
                )}
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Box sx={{ pb: [3] }}>
                  <Text sx={{ fontSize: [5], color: "light" }}>
                    {item.title}
                  </Text>
                </Box>
                <Box sx={{ pb: [3] }}>
                  <Text sx={{ color: "light", fontWeight: "600" }}>
                    {item.subtitle}
                  </Text>
                </Box>
                <Box
                  sx={{
                    p: {
                      fontSize: "15px",
                      fontWeight: "400",
                    },
                  }}
                >
                  <RichContentStructuredText text={item.body} theme="dark" />
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export default LandingitemCarousel
