import React, { useEffect } from "react"
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  Image,
  Container,
} from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { OutboundLink } from "../link"
import FacebookIcon from "../../images/facebook.inline.svg"
import InstagramIcon from "../../images/instagram.inline.svg"
import { useCompany } from "../../hooks/useCompany"
import { useSocial } from "../../hooks/useSocial"

const ContactInfo = ({ title, image, description, link, index }) => {
  const privacy = "https://www.iubenda.com/privacy-policy/79004545"
  const cookie = "https://www.iubenda.com/privacy-policy/79004545/cookie-policy"

  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://cdn.iubenda.com/iubenda.js"
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const social = useSocial()
  const company = useCompany()

  return (
    <Box sx={{ position: "relative", py: index != 0 && [7] }}>
      <Container>
        <Grid columns={["1fr", "1fr", "1fr 1fr"]} gap={[4]}>
          <Box
            sx={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              width: ["100%", "100%", "100%"],
              height: "100%",
              ".gatsby-image-wrapper": {
                height: "100%",
                minHeight: ["300px"],
              },
              img: {
                height: "100%",
                minHeight: ["300px"],
              },
            }}
          >
            {image.gatsbyImageData ? (
              <GatsbyImage image={image.gatsbyImageData} alt="" />
            ) : (
              <Image src={image.url} alt={title} />
            )}
          </Box>
          <Flex
            sx={{
              pt: index === 0 && [4, 8],
              flexDirection: "column",
            }}
          >
            <Box>
              <Heading
                as="div"
                dangerouslySetInnerHTML={{ __html: title }}
                sx={{
                  "h1,h2": {
                    mb: [3],
                    fontFamily: "heading",
                    lineHeight: "heading",
                    fontWeight: "heading",
                    fontSize: [5, 7],
                    color: "dark",
                    em: {
                      color: "light",
                      fontStyle: "normal",
                    },
                  },
                }}
              />
            </Box>
            <Text
              sx={{
                maxWidth: "650px",
                fontFamily: index == 0 && "heading",
                color: index === 1 && "light",
                p: {
                  fontSize: [3],
                  mb: link && [6],
                },
                ul: {
                  position: "relative",
                  listStyle: "none",
                  marginLeft: "0",
                  paddingLeft: "1.2em",
                  li: {
                    fontSize: [3],
                    fontWeight: "700",
                    position: "relative",
                  },
                  "li:before": {
                    content: '""',
                    width: "18px",
                    height: "2px",
                    backgroundColor: "light",
                    position: "absolute",
                    top: "30%",
                    transform: "translateY(-30%)",
                    left: "-1.2em",
                  },
                },
              }}
              variant="p"
              as="div"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <Box>
              {link && (
                <OutboundLink variant="buttons.primary" to={link.url}>
                  {link.anchor}
                </OutboundLink>
              )}
            </Box>
          </Flex>
        </Grid>
      </Container>
      <Box
        as="footer"
        sx={{ backgroundColor: "#4ADA85", pt: [5, 4], color: "light" }}
      >
        <Container>
          <Flex
            sx={{
              justifyContent: "space-between",
              pb: [3],
              borderBottom: "1px solid",
              borderColor: "dark",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ width: ["100%", "80%"] }}>
              <RichContentStructuredText text={company.body} />
            </Box>
            <Flex
              sx={{
                a: {
                  display: "inline-block",
                  svg: {
                    width: "48px",
                    height: "48px",
                  },
                },
              }}
            >
              {social && (
                <>
                  {social.facebook && (
                    <Box sx={{ mr: 2 }}>
                      <OutboundLink to={social.facebook}>
                        <FacebookIcon />
                      </OutboundLink>
                    </Box>
                  )}
                  {social.instagram && (
                    <Box>
                      <OutboundLink to={social.instagram}>
                        <InstagramIcon />
                      </OutboundLink>
                    </Box>
                  )}
                </>
              )}
            </Flex>
          </Flex>
          <Flex sx={{ justifyContent: "space-between" }}>
            <Box sx={{ fontSize: [0], color: "dark", pt: [3] }}>
              AFI s.r.l. - Sede legale: Viale Alfieri, 26 - 21052 Busto Arsizio
              (VA) - Cap. Soc. €11.000 i.v. Reg. Imprese di Varese n. 43897/1998
              - Cod.Fisc. / P.IVA 02470470127 - R.E.A. Varese n. 260296 |{" "} <OutboundLink
                sx={{
                  fontSize: [0],
                }}
                href={privacy}
                className="iubenda-nostyle no-brand iubenda-embed"
                title="Privacy Policy"
              >
                Privacy Policy
              </OutboundLink>
              {" | "}
              <OutboundLink
                sx={{
                  fontSize: [0],
                }}
                href={cookie}
                className="iubenda-nostyle no-brand iubenda-embed"
                title="Cookie Policy"
              >
                Cookie Policy
              </OutboundLink>
              {" "}© 2022 Famiglia in Linea - by MULTI
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default ContactInfo
