import React, { useState, useEffect } from "react"
import { useBreakpointIndex } from "@theme-ui/match-media"
import { Box, Flex, Grid } from "@theme-ui/components"
import * as Scroll from "react-scroll"
import SidebarDot from "./sidebarDot"

const SidebarDots = ({ blocks }) => {
  const LinkScroll = Scroll.Link
  const [activeBG, setActiveBG] = useState()
  const [hovered, setHovered] = useState()

  return (
    <Box
      sx={{
        display: ["none", "none", "none", "block"],
        position: "fixed",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 3,
      }}
    >
      {blocks.map((block, index) => (
        <Box
          sx={{
            px: [3],
            a: {
              textDecoration: "none",
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              position: "relative",
              cursor: "pointer",
              color: activeBG === "light" ? "lighten" : "lightGrey",
              "&:before": {
                content: "''",
                mr: 1,
                mb: 2,
                width: "10px",
                height: "2px",
                backgroundColor: activeBG,
                transition: "all .3s ease",
              },
              "&:hover:before": {
                mr: 1,
                color: activeBG,
              },
              "&:hover": {
                mr: 1,
                color: activeBG,
              },
              "&.activeDot": {
                color: activeBG,
              },
              "&.activeDot:before": {
                width: "20px",
                mr: 1,
                backgroundColor: activeBG,
              },
              transition: "all .3s ease",
            },
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <SidebarDot
            activeBG={setActiveBG}
            hovered={hovered}
            to={block.id}
            bg={block.bg}
          />
        </Box>
      ))}
    </Box>
  )
}

export default SidebarDots
