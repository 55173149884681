import React, { useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { Container, Heading, Box } from "@theme-ui/components"
import Layout from "../components/layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import ImageAndText from "../components/blocks/imageAndText"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import ItemCarousel from "../components/blocks/itemCarousel"
import HighlightsCarousel from "../components/blocks/highlightsCarousel"
import ContactInfo from "../components/blocks/contactInfo"
import SidebarDots from "../components/sidebarDots"
// import { gsap} from "gsap"
// import ScrollTrigger from "gsap/ScrollTrigger"

const Home = ({ data: { page, site, articles } }) => {
  const colors = [
    "#FDA4FF",
    "#1B9DD9",
    "#FED101",
    "#8C7BFF",
    "#FFFFFF",
    "#4ADA85",
  ]
  const primary = getColor(themeUiTheme, "primary")
  const secondary = getColor(themeUiTheme, "secondary")

  const favicon = useFavicon().site.faviconMetaTags

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  const blockIds = []

  page.content.map(block => {
    blockIds.push({
      id: block.id,
      title: block.menuText && block.menuText,
      bg: block.model.apiKey === "highlights_collection" ? "dark" : "light",
    })
  })

  // useEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger)

  //   gsap.utils.toArray(".panel").forEach((panel, i) => {
  //     ScrollTrigger.create({
  //       trigger: panel,
  //       start: "top top",
  //       pin: true,
  //       pinSpacing: false,
  //     })
  //   })

  //   ScrollTrigger.create({
  //     snap: 1 / 4, // snap whole page to the closest section!
  //   })
  // }, [])

  return (
    <Layout locale={page.locale} pageSections={blockIds} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <SidebarDots blocks={blockIds} />
      {page.content.map((block, index) => (
        <>
          <Box
            as="section"
            className="panel"
            key={block.id}
            id={block.id}
            sx={{ backgroundColor: colors[index], pt: index === 0 && [5, 8] }}
          >
            {block.model.apiKey === "image_and_text" && (
              <ImageAndText
                title={block.title}
                description={block.description}
                image={block.image}
                link={block.link}
                index={index}
              />
            )}
            {block.model.apiKey === "item_carousel" && (
              <ItemCarousel items={block.items} title={block.title} />
            )}
            {block.model.apiKey === "highlights_collection" && (
              <HighlightsCarousel items={block.highlight} title={block.title} />
            )}
            {block.model.apiKey === "contact_info" && (
              <ContactInfo
                title={block.title}
                description={block.description}
                image={block.image}
                link={block.link}
                index={index}
              />
            )}
          </Box>
        </>
      ))}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsImageAndText {
          id
          menuText
          image {
            url
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          title
          description
          link {
            ... on DatoCmsExternalLink {
              id
              anchor
              url
              model {
                apiKey
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactInfo {
          id
          menuText
          model {
            apiKey
          }
        }
        ... on DatoCmsHighlightsCollection {
          id
          menuText
          title
          highlight {
            title
            subtitle
            body {
              value
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          menuText
          title
          items {
            id
            title
            subtitle
            body {
              value
            }
            image {
              url
              gatsbyImageData(
                width: 1480
                placeholder: BLURRED
                forceBlurhash: false
              )
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactInfo {
          id
          menuText
          image {
            url
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          title
          description
          link {
            ... on DatoCmsExternalLink {
              id
              anchor
              url
              model {
                apiKey
              }
            }
          }
          model {
            apiKey
          }
        }
      }
    }

    site: datoCmsSite {
      locales
    }
  }

  fragment RichContent on DatoCmsRichContent {
    title
    label
    subtitle
    image {
      url
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    body {
      value
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
        }
      }
      value
    }

    model {
      apiKey
    }
  }
`
