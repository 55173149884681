import React from "react"
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  Image,
  Container,
} from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { OutboundLink } from "../link"



const ImageAndText = ({ title, image, description, link, index }) => {
  return (
    <Box sx={{ position: "relative", py: [4, index != 0 && 7] }}>
      <Container>
        <Grid columns={["1fr","1fr","1fr 1fr"]} gap={[0]}>
          <Flex
            sx={{
              gridRow:[2,2,1],
              pt: index === 0 && [4,8],
              flexDirection: "column",
            }}
          >
            <Box>
              <Heading
                as="div"
                dangerouslySetInnerHTML={{ __html: title }}
                sx={{
                  "h1,h2": {
                    mb: [3],
                    fontFamily: "heading",
                    lineHeight: "heading",
                    fontWeight: "heading",
                    fontSize: [5,7],
                    color: "dark",
                    em: {
                      color: "light",
                      fontStyle: "normal",
                    },
                  },
                }}
              />
            </Box>
            <Text
              sx={{
                maxWidth: "650px",
                fontFamily: index == 0 && "heading",
                color: index === 1 && "light",
                p: {
                  fontSize: [2,3],
                  mb: link && [4,6],
                },
                ul: {
                  position: "relative",
                  listStyle: "none",
                  marginLeft: "0",
                  paddingLeft: "1.2em",
                  li: {
                    fontSize:[3],
                    fontWeight:"700",
                    position: "relative",
                  },
                  "li:before": {
                    content: '""',
                    width: "18px",
                    height: "2px",
                    backgroundColor: "light",
                    position: "absolute",
                    top: "30%",
                    transform: "translateY(-30%)",
                    left: "-1.2em",
                  },
                },
              }}
              variant="p"
              as="div"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <Box>
              {link && (
                <OutboundLink variant="buttons.primary" to={link.url}>
                  {link.anchor}
                </OutboundLink>
              )}
            </Box>
          </Flex>
          <Box
            sx={{
              gridRow:[1,1,1],
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              width: ["100%", "100%", "100%"],
              height: "100%",
              ".gatsby-image-wrapper": {
                height: "100%",
                minHeight:["300px"]
              },
              img: {
                height: "100%",
                minHeight:["300px"]
              },
            }}
          >
            {image.gatsbyImageData ? (
              <GatsbyImage image={image.gatsbyImageData} alt="" />
            ) : (
              <Image src={image.url} alt={title} />
            )}
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default ImageAndText
