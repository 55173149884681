import React, { useState, useEffect } from "react"
import { Box, Flex, Grid } from "@theme-ui/components"
import * as Scroll from "react-scroll"

const SidebarDot = ({ to, activeBG, bg, hovered, children, ...props }) => {
  const LinkScroll = Scroll.Link
  const [active, setActive] = useState(false)

  useEffect(() => {
    const ioOptions = {
      threshold: 0.5,
    }
    const ioCallback = (entries, observer) => {
      const entry = entries[0]
      setActive(entry.isIntersecting)
    }
      
    if (active) activeBG(bg)

    const observer = new IntersectionObserver(ioCallback, ioOptions)
    observer.observe(document.querySelector(`#${to}`))
  }, [to, active])

  return (
    <Box
      sx={{
        py: ["2px"],
        span: {
          opacity: hovered ? 1 : 0,
          "-webkit-transition": "opacity .1s linear",
          "-ms-transition": "opacity .1s linear",
          transition: "opacity .1s linear",
        },
      }}
    >
      <LinkScroll className={active && "activeDot"} smooth to={to} {...props}>
      </LinkScroll>
    </Box>
  )
}

export default SidebarDot
