import { Box, Text, Container, Flex, Heading } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"

import { Mousewheel, Pagination, Keyboard } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper.min.css"
import RichContentStructuredText from "../richContentStructuredText"

const HighlightsCarousel = ({ items, title }) => {
  const customContainerRef = useRef()
  const [marginLeft, setMarginLeft] = useState()

  useEffect(() => {
    setMarginLeft(customContainerRef.current.getBoundingClientRect().left)
    function handleResize() {
      setMarginLeft(customContainerRef.current.getBoundingClientRect().left)
      console.log(customContainerRef.current.getBoundingClientRect())
    }
    window.addEventListener("resize", handleResize)
  }, [])

  const colors = ["#FDA4FF", "#1B9DD9", "#FED101", "#8C7BFF", "#4ADA85"]

  return (
    <Box
      sx={{
        py: [5],
        ".mySwiper .swiper-wrapper .swiper-slide:first-child": {
          ml: marginLeft,
          pl: [3, 4],
        },
        ".mySwiper .swiper-pagination": {
          ml: [marginLeft + 16, marginLeft + 32],
        },
        ".mySwiper .swiper-wrapper .swiper-slide:last-child": {
          pr: [marginLeft / 2 - 16, marginLeft / 2 - 32],
        },
        ".mySwiper .swiper-slide": {
          maxWidth: "765px",
          height: "auto",
          mb: [3, 6],
          img: {
            height: "100%",
          },
        },
      }}
    >
      <Container
        className="customcontainer"
        ref={customContainerRef}
        sx={{ py: [0, 0] }}
      ></Container>
      <Container sx={{ py: [0, 0] }}>
        <Box sx={{ textAlign: "left", pb: [4,6] }}>
          <Heading
            as="h2"
            sx={{
              mb: [3],
              fontFamily: "heading",
              lineHeight: "heading",
              fontWeight: "heading",
              fontSize: [5,7],
              color: "dark",
            }}
          >
            {title}
          </Heading>
        </Box>
      </Container>
      <Swiper
        modules={[Mousewheel, Keyboard]}
        mousewheel={{
          forceToAxis: true,
          sensitivity: 1,
          releaseOnEdges: true,
        }}
        autoHeight={false}
        className="mySwiper"
        spaceBetween={30}
        centeredSlides={true}
        centeredSlidesBounds={true}
        preventClicks={true}
        simulateTouch={false}
        onSlideChange={() => console.log("slide change")}
        onSwiper={swiper => console.log(swiper)}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          896: {
            slidesPerView: 2,
          },
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide>
            <Box
              key={item.id}
              sx={{
                pl: [index != 0 && 3, 0],
                pr: [3,0],
                height: "100%",
                maxWidth: "765px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  border: "1px solid",
                  p: [3, 4],
                  height:"100%"
                }}
              >
                <Box sx={{ pb: [3] }}>
                  <Text
                    sx={{
                      fontSize: [3, 5],
                      lineHeight: 1,
                      color: `${
                        colors[Math.floor(Math.random() * colors.length)]
                      }`,
                      fontSize: [5, "90px"],
                      textTransform: "lowercase",
                    }}
                  >
                    {item.title}
                  </Text>
                </Box>
                <Box sx={{ pb: [3] }}>
                  <Text
                    sx={{
                      color: "dark",
                      lineHeight: 1,
                      fontWeight: "400",
                      fontSize: [5, "90px"],
                      textTransform: "lowercase",
                    }}
                  >
                    {item.subtitle}
                  </Text>
                </Box>
                <Box
                  sx={{
                    p: {
                      fontSize: [3],
                      fontWeight: "400",
                    },
                  }}
                >
                  <RichContentStructuredText text={item.body} theme="light" />
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export default HighlightsCarousel
